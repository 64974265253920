import axios from "axios";

const qs = require("qs");


const API = process.env.GATSBY_API_URL;

const configUpload = () => ({
    headers: {
        "content-type": "multipart/form-data",
    },
    withCredentials: true,
});


export const fetchAllIntegrationCreds = async (params) =>
    await axios.get(
        `${process.env.GATSBY_API_URL}/integrations/credentials/details`,

        {
            params,
            withCredentials: true
        }
    )


export const generateCoupon = async (dto) =>
    await axios.post(
        `${process.env.GATSBY_API_URL}/tiendanube/claim/coupons`,
        dto,
        {
             withCredentials: true
        }
    )


