import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import SEO from "../../components/Layout/Seo"
import tw from "twin.macro"

import imageBgFotos from "../../images/fondo_precios.svg"
import LayoutPage from "../../components/global/Layout"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import SectionCard from "../../components/Card/SectionCard"
import styled from "styled-components"
import WindowLayout from "../../components/Layout/WindowLayout"
import LoadingAnimation from "../../components/Layout/LoadingAnimation"
import { Form, Formik, FormikHelpers, FormikValues } from "formik"
import { sleep, wrapErrorMessageText } from "../../utils/general"
import { useChat } from "../../components/Chat/ChatContext"
import { fetchAllIntegrationCreds, generateCoupon } from "../../components/tiendanube/tiendanubeApi"
import FieldAGStatic from "../../components/Form/FieldAGStatic"
import SwitchAG from "../../components/Layout/SwitchAG"
import SwitchAgStatic from "../../components/Layout/SwitchAGStatic"
import { ClaimerCoupon } from "../../components/appStore/ClaimerCoupon"


const IndexPage = () => {
    let content


    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${imageBgFotos})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    minHeight: "calc(100vh -  0px)",
                }}
            >
                <LayoutPage>
                    <div
                        css={tw`w-full flex     pt-100px     mx-auto max-w-content px-8 justify-between `}
                    >
                        <div
                            css={tw`  space-y-4  lg:my-auto flex-col w-full text-center  lg:text-left  `}
                        >
                            <div tw="space-y-4 mt-8">
                                <h1
                                    css={tw`text-3xl lg:text-4xl text-center lg:mt-0 text-autogestiones-card   `}
                                >
                                    ¡Potenciá tu   tienda
                                    <span
                                        css={tw`font-bold`}
                                        className="text-gradient"
                                    >{" "}
                                        con esta promoción exclusiva!
                                    </span>
                                </h1>
                                <div
                                    css={tw`grid    mt-0 lg:my-auto  pt-0  lg:text-left text-center  `}
                                >
                                    <StaticImage
                                        src="./../../images/tiendanube/precio-1.png"
                                        width={1150}
                                        quality={100}
                                        placeholder="none"
                                        css={tw`mx-auto w-500px`}
                                        formats={["auto", "webp", "avif"]}
                                        alt="precios para usar autogestiones mayorista"
                                    />{" "}

                                </div>

                                <p css={tw`text-gray-300 text-base text-center lg:text-base font-normal w-full pt-4`}>
                                    Obtén un descuento del 15% al vincular tu cuenta de Tiendanube
                                </p>

                            </div>
                        </div>
                    </div>

                    <div
                        css={tw`w-full gap-8 flex mt-8  flex-col  mx-auto max-w-content px-8 justify-between `}
                    >
                        <SectionCard >
                            <div css={tw`flex-col  flex justify-center items-center space-y-4`}>
                                <p
                                    css={tw`text-blackest font-medium text-center   text-lg lg:text-2xl leading-loose`}
                                >
                                    Dejá a un lado los{" "}
                                    <span css={tw`font-bold text-autogestiones-100`}>
                                        sistemas obsoletos
                                    </span>{" "}
                                    que no se adaptan a vos
                                </p>

                                <ClaimerCoupon />
                            </div>
                        </SectionCard>
                        <p css={tw`text-sm mt-4 text-gray-400 text-center`}>
                            *Términos y Condiciones: Este premio es válido únicamente para cuentas de Autogestiones con Tiendanube de Argentina. No es transferible y solo puede ser utilizado una vez por cada cuenta de Tiendanube del usuario. El premio no tiene valor monetario y no puede ser canjeado por efectivo ni por ningún otro premio. No es acumulable con otros premios o promociones. El titular de la cuenta es responsable de cumplir con cualquier requisito adicional especificado para el uso del premio. Autogestiones se reserva el derecho de modificar o cancelar este premio en cualquier momento sin previo aviso. El uso de este premio está sujeto a los términos y condiciones generales de Autogestiones, disponibles en su sitio web oficial.
                        </p>
                    </div>

                </LayoutPage>

            </div>
        </>
    )
}

export default IndexPage

export const Head = () =>
    <SEO description=""
        lang="es-AR" title="Potencía tu tienda de Tiendanube" />