import React, { useState } from "react"
import tw from "twin.macro"

const FieldAGStatic = ({
	label = "",
	name = null,
	placeholder = "Escriba...",
	type = "text",
	onChange = (e) => {},
	onClick = (e) => {},
	value = "",
	isEditable = true,
	className = "",
	style = {},
	isRequired = true,
	min = "",
}) => {
	const [isError, setIsError] = useState(false)

	return (
		<div>
			{label ? (
				<label
					htmlFor={name}
					css={tw`block tracking-wide text-gray-100 text-xs text-left mb-1`}
				>
					{label}
				</label>
			) : null}
			<input
				onBlur={() => {
					if (value === "" && isRequired === true) {
						setIsError(true)
					} else setIsError(false)
				}}
				css={
					!isError
						? tw`appearance-none text-base sm:text-sm placeholder-gray-500 text-white border w-full bg-transparent rounded py-2 md:py-3 px-3 leading-tight focus:outline-none focus:border-autogestiones-100 border-autogestiones-200`
						: tw`appearance-none text-base sm:text-sm placeholder-gray-500 text-white border w-full bg-transparent rounded py-2 md:py-3 px-3 leading-tight focus:outline-none focus:border-autogestiones-100 border-red-600`
				}
				type={type}
				min={min}
				style={{ ...style, height: 40 }}
				name={name}
				id={name}
				placeholder={placeholder}
				onChange={onChange}
				onClick={onClick}
				value={value}
			/>
		</div>
	)
}

export default FieldAGStatic
