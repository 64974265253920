import React from "react";

const SwitchAgStatic = ({ value, onChange, name = "", label = "" }: {
  value: any
  onChange(x: any): void
  name?: string
  label?: string
}) => {
  return (
    <div className={` flex gap-2  justify-start items-center `}>
      <style  >
        {`
          /* SWITCH */

          /* The switch - the box around the slider */
          .switch {
            position: relative;
            display: inline-block;
            width: 53px;
            height: 27px;
          }

          /* Hide default HTML checkbox */
          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          /* The slider */
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #161d31;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 6px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            margin-bottom: 0.0625rem;
          }

          input:checked + .slider {
            background-color: #5d67f0;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px #5d67f0;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(23px);
            -ms-transform: translateX(23px);
            transform: translateX(23px);
          }

          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        `}
      </style>
      <div
        className="relative  overflow-hidden"
        style={{ height: 28, width: 54 }}
      >
        <label className="switch absolute left-0">
          <input
            type="checkbox"
            checked={value}
            onChange={onChange}
            name={name}
          />
          <span className="slider round"></span>
        </label>
      </div>
      {!!label && (
        <span className="tracking-wide text-gray-100 lg:text-sm text-base my-auto">
          {label}
        </span>
      )}
    </div>
  );
};

export default SwitchAgStatic;
